<template>

  <div>
    <div id="printMeCompany" style="width: 701px;text-align: center;background: #eeeeee">
      <div style="border-bottom:1px solid slategray;width: 100%;font-size: 35px;padding-top: 20px; padding-bottom: 20px">
        {{company.name}}
      </div>
      <div style="border-bottom:1px solid slategray;padding-top: 20px; padding-bottom: 20px">
        <div v-for="version in company.versionCompanyList" >
          <div style="padding:10px;width: 180px;display:table-cell;font-size: 19px;font-weight: bold;color: cornflowerblue">{{version.versionName}}</div>
          <div style="padding:10px;width: 180px;display:table-cell;font-size: 18px;color: cornflowerblue">价格：{{version.price}}</div>
          <div style="padding:10px;width: 180px;display:table-cell;font-size: 18px;color: cornflowerblue">总件数： {{version.total}}</div>
        </div>
      </div>
      <div style="border-bottom:1px solid slategray;padding-top: 20px; padding-bottom: 20px ;font-size: 25px">
        <div>总计：{{company.alltotal}}</div>
      </div>
      <p>此账单每个款式价格仅表示最后一次修改的价格</p>
    </div>
    <div style="text-align: center;margin-top: 50px;margin-bottom: 100px">
      <el-button type="success" ref="printCompany" v-show="false" v-print="'#printMeCompany'"></el-button>
      <el-button type="success" @click="printCompany" >打印</el-button>
    </div>







    <div id="printMeChange" style="width: 701px;text-align: center;background: #eeeeee">
      <div style="border-bottom:1px solid slategray;width: 100%;font-size: 35px;padding-top: 20px; padding-bottom: 20px">
        {{company.name}}
      </div>
      <div style="border-bottom:1px solid slategray;padding-top: 20px; padding-bottom: 20px">
        <div v-for="version in company.versionCompanyList" >
          <div style="padding:10px;width: 180px;display:table-cell;font-size: 19px;font-weight: bold;color: cornflowerblue">{{version.versionName}}</div>
          <div style="padding:10px;width: 180px;display:table-cell;font-size: 18px;color: cornflowerblue">价格：{{version.price}}</div>
          <div style="padding:10px;width: 180px;display:table-cell;font-size: 18px;color: cornflowerblue">总件数： {{version.total}}</div>

          <div >



            <el-table
                :data="version.versionchangeList"
                border
                style="width: 100%"
                v-if="version.versionchangeList.length != 0">
              <!--                    <el-table-column-->
              <!--                        fixed-->
              <!--                        prop="id"-->
              <!--                        label="单号"-->
              <!--                        width="120">-->
              <!--                    </el-table-column>-->
              <el-table-column
                  prop="price"
                  label="修改前价格"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="total"
                  label="修改时总件数"
                  width="170">
              </el-table-column>
              <el-table-column
                  prop="changeTotal"
                  label="修改前的价格所计件数"
                  width="190">
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  label="修改时间"
                  width="190" >
              </el-table-column>


            </el-table>
          </div>
        </div>
      </div>
      <div style="padding-top: 20px; padding-bottom: 20px ;font-size: 25px">
        <div>总计：{{company.alltotal}}</div>
      </div>
    </div>
    <div style="text-align: center;margin-top: 50px;margin-bottom: 100px">
      <el-button type="success" ref="printChange" v-show="false" v-print="'#printMeChange'"></el-button>
      <el-button type="success" @click="printChange" >打印</el-button>
    </div>






    <div id="printMePay" style="width: 701px;text-align: center;background: #eeeeee">
      <div style="border-bottom:1px solid slategray;width: 100%;font-size: 35px;padding-top: 20px; padding-bottom: 20px">
        {{company.name}}
      </div>
      <div style="border-bottom:1px solid slategray;padding-top: 20px; padding-bottom: 20px">



        <el-table
            :data="company.paycompanyList"
            border
            style="width: 100%"
            >

          <el-table-column
              prop="money"
              label="支付金额"
              width="350">
          </el-table-column>
          <el-table-column
              prop="dataTime"
              label="支付时间"
              width="350">
          </el-table-column>



        </el-table>

        <div style="padding-top: 20px; padding-bottom: 20px ;font-size: 25px">
          <div style="width:220px;display:table-cell">总计：{{company.alltotal}}</div><div style="width:220px;display:table-cell">已支付：{{company.settlement}}</div><div style="width:220px;display:table-cell">未支付：{{company.alltotal-company.settlement}}</div>
        </div>
      </div>



    </div>
    <div style="text-align: center;margin-top: 50px">
      <el-button type="success" ref="printPay" v-show="false" v-print="'#printMePay'"></el-button>
      <el-button type="success" @click="printPay" >打印</el-button>
    </div>









  </div>


</template>

<script>

import print from 'vue-print-nb'
export default {
  name: "PrintCompany",
  directives:{
    print,
  },
  data() {
    return {
      company: '',
    }
  },
  created() {
    const _this = this
    _this.company = this.$route.query.company
  },
  methods:{
    printCompany(){
      this.$refs.printCompany.$el.click();
    },
    printChange(){
      this.$refs.printChange.$el.click();
    },
    printPay(){
      this.$refs.printPay.$el.click();
    },
  }
}
</script>


<style scoped>

</style>