<template>

  <div style="width: 1000px;background: #B3C0D1;margin-left: 100px">
    <div id = "printMe">
      <div style="font-size: larger">
        <span style="font-weight: bold">服装厂名称:&nbsp</span><span>{{versionVO.companyName}}</span>
        <p></p>
        <span style="font-weight: bold">服装版本:&nbsp&nbsp&nbsp&nbsp</span><span>{{versionVO.versionName}}</span>
      </div>
      <table border="1" width="1000px"style="margin: auto;">
        <tr>
          <th width="200px">名称</th>
          <th width="800px  ">克重</th>
        </tr>
        <tr v-for="item in versionAttribute">
          <td>{{item.name}}</td>
          <td>{{item.number}}</td>
        </tr>
      </table>
    </div>



    <div style="text-align: center;margin-top: 50px">
      <el-button type="success" ref="print" v-show="false" v-print="'#printMe'"></el-button>
      <el-button type="success" @click="print" >打印</el-button>
    </div>
  </div>

</template>

<script>

import print from 'vue-print-nb'
export default {
  directives:{
    print,
  },

  name: "VersionPrint",
  methods:{
    print(){
      this.$refs.print.$el.click();
    },
  },
  created() {
    const _this = this
    _this.versionVO = this.$route.query.versionVO
    _this.versionAttribute = this.$route.query.versionAttribute
  },


  data() {

    return {

      versionAttribute:[{}],
      versionVO:''
    }
  }
}
</script>

<style scoped>
table {
  width: 450px;
  min-height: 30px;
  line-height: 30px;
  text-align: center;
  border-collapse: collapse;
  font-size: larger;
}

table,
table tr th,
table tr td {
  border: 1px solid #a8aeb2;
  padding: 5px 10px;
}

</style>