<template>

  <div>
    <div style="text-align: center;margin-bottom:20px ;width: 500px;height: 160px">
      <el-form  :model="ruleForm"  ref="ruleForm"  style="position: absolute">
        <el-row>
          <el-col :span="12">
            <el-form-item label="服装厂:" prop="companyId" style="width: 330px">
              <el-select @change="search('ruleForm')"  @focus="clear('ruleForm')"  v-model="ruleForm.companyId" filterable placeholder="请选择"  style="width: 230px">
                <el-option
                    v-for="item in dataGet.companyIdOption"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服装型号:" prop="versionId" style="width: 320px">
              <el-select v-model="ruleForm.versionId" filterable placeholder="请选择"style="width: 230px">
                <el-option
                    v-for="item in dataGet.versionIdOption"
                    :key="item.id"
                    :label="item.versionName"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row>
          <el-col :span="12">
            <el-form-item label="工人" prop="userId" style="width: 348px">
              <el-select v-model="ruleForm.userId" filterable placeholder="请选择"style="width: 230px">
                <el-option
                    v-for="item in dataGet.userIdOption"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="创建日期" prop="createTime">
              <el-date-picker
                  v-model="ruleForm.createTime"
                  type="date"
                  placeholder="选择日期"style="width: 230px">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>


        <el-button type="primary" @click="submitForm('ruleForm')">搜索</el-button>
      </el-form>

    </div>
    <div>

      <el-table
          :data="goodsVO"
          border
          style="width: 100%" >
        <el-table-column
            fixed
            prop="id"
            label="单号"
            width="120">
        </el-table-column>
        <el-table-column
            prop="companyName"
            label="厂家名"
            width="120">
        </el-table-column>
        <el-table-column
            prop="versionName"
            label="服装型号"
            width="120">
        </el-table-column>
        <el-table-column
            prop="count"
            label="件数"
            width="120">
        </el-table-column>
        <el-table-column
            prop="userName"
            label="计件员工"
            width="120">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            width="170">
        </el-table-column>
        <el-table-column
            prop="finishTime"
            label="员工计件时间"
            width="170" >
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="修改时间"
            width="170">
        </el-table-column>




        <el-table-column
            label="操作"
            width="120">
          <template slot-scope="scope">

            <el-button @click="printNow(scope.row)" type="text" size="small">打印</el-button>
            <el-button @click="editor(scope.row)" type="text" size="small">修改</el-button>
            <el-button @click="deleteProduct(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          layout="prev, pager, next"
          :page-size="size"
          :total="total"
          @current-change="page">
      </el-pagination>
    </div>

  </div>

</template>

<script>
import axios from 'axios'
export default {
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    search(){
      const _this = this

      // axios.get('http://localhost:8181/version/VUEgetAll/').then(function (resp){
      axios.get(this.api.URL+'version/VUEshowVersionByCompanyId/'+this.ruleForm.companyId).then(function (resp){
        // _this.product = resp.data.records
        _this.dataGet.versionIdOption = resp.data

        _this.dataGet.versionIdOption.unshift(_this.versionnull)
      })

    },
    clear(){
      const _this = this
      _this.dataGet.versionIdOption = {}
      _this.ruleForm.versionId = ''
    },




    submitForm(formName) {
      const _this = this
      this.$refs[formName].validate((valid) => {
        if (valid){
          const that = this
          var time = new Date(_this.ruleForm.createTime)
          let yy = time.getFullYear()
          let mm = time.getMonth() + 1
          let dd = time.getDate()
          const createTime = yy + '-' + mm + '-' + dd
          _this.ruleForm.createTime = createTime
          var a = parseInt(_this.ruleForm.createTime);


          if( isNaN(a)){
            _this.ruleForm.createTime = ""
          }
          console.log(_this.ruleForm.createTime)
          // console.log(_this.ruleForm)

          axios.post(this.api.URL+'goods/search/',this.ruleForm).then(function (resp){
            if(resp.data != ""){
              _this.goodsVO = resp.data
              _this.total = 0
            }else {
              alert("不存在数据")
            }
          })
        }
      })
    },
    printNow(row){
      this.$router.push({
        path:"/goodsPrint",
        query:{
          goodsVO:row
        }
      })
    },
    editor(row) {
      this.$router.push({
        path:"/goodsChange",
        query:{
          goodsVO:row
        }
      })
    },
    deleteProduct(row){
      const _this = this
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const __this = this
        // alert(row.id)
        axios.get(this.api.URL+'goods/delete/'+row.id).then(function (resp){
          if(resp.data == "ok"){
            window.location.reload()
            __this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            __this.$message({
              type: 'error',
              message: '删除失败!'
            });
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    page(currentPage){
      const _this = this
      axios.get(this.api.URL+'goods/page/'+currentPage +"/" +8).then(function (resp){
        _this.goods = resp.data.records
      })

      axios.get(this.api.URL+'goods/pageDESC/'+currentPage +"/" +8).then(function (resp){
        _this.goodsVO = resp.data
      })
    }
  },

  data() {
    return {
      usernull:{
        id:null,
        name:null
      },
      companynull:{
        id:null,
        name:null,
      },
      versionnull:{
        id:null,
        versionName:null
      },
      dataGet:{
        companyIdOption: [{}],
        versionIdOption: [{}],
        userIdOption:[{}]
      },
      total: null,
      goods: [],
      goodsVO:[],
      version:[],
      company:[],
      user:[],
      size:8,

      ruleForm:{
        companyId: '',
        versionId: '',
        userId:'',
        createTime:''
      },

      rules: {

      },

      value:'',

    }
  },


  created() {


    //这里的this.product指的是data中的product
    //this.product
    const _this = this

    axios.get(this.api.URL+'goods/page/1/8').then(function (resp){
      _this.goods = resp.data.records
      _this.total = resp.data.total
    })
    axios.get(this.api.URL+'goods/pageDESC/1/8').then(function (resp){
      _this.goodsVO = resp.data
    })

    axios.get(this.api.URL+'company/showAllCompany/').then(function (resp){
      // _this.product = resp.data.records
      // console.log(resp.data)
      _this.dataGet.companyIdOption = resp.data

      _this.dataGet.companyIdOption.unshift(_this.companynull)
    })
    axios.get(this.api.URL+'user/findall/').then(function (resp){
      // _this.product = resp.data.records
      // console.log(resp.data)
      _this.dataGet.userIdOption = resp.data

      _this.dataGet.userIdOption.unshift(_this.usernull)
    })
  }
}
</script>

<style scoped>

</style>