<template>
  <el-container>
    <el-form>
      <div style="width: 800px ; text-align: center;font-size: 22px">
        修改计件信息
      </div>


      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" style="margin-left: 100px" >
        <el-form-item label="服装厂" prop="companyId">
          <el-select @change="search('ruleForm')"    v-model="ruleForm.companyId" filterable placeholder="请选择"  style="width: 230px">
            <el-option
                v-for="item in dataGet.companyIdOption"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服装型号" prop="versionId">
          <el-select v-model="ruleForm.versionId" filterable placeholder="请选择"style="width: 230px">
            <el-option
                v-for="item in dataGet.versionIdOption"
                :key="item.id"
                :label="item.versionName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="计件员工" prop="userId">
          <el-select v-model="ruleForm.userId" filterable placeholder="请选择"style="width: 230px">
            <el-option
                v-for="item in dataGet.userIdOption"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量" prop="count">
          <el-input v-model.number="ruleForm.count" style="width: 230px" ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-form>
  </el-container>








</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      usernull:{
        id:null,
        name:null
      },
      goodsVO:{},
      ruleForm:{
        id:'',
        companyId: '',
        versionId: '',
        userId:'',
        count:''
      },
      dataGet:{
        companyIdOption: [{}],
        versionIdOption: [{}],
        userIdOption:[{}],
      },
      rules: {
        companyId:[
          { required: true, message: '请选择服装厂', },
        ],
        versionId:[
          { required: true, message: '请选择服装型号', },
        ],
        count: [
          { required: true, message: '请输入计件数量', },
          { type: 'number' }
        ]
      }
    }
  },



  created(){
    const _this = this
    _this.goodsVO = this.$route.query.goodsVO
    axios.get(this.api.URL+'company/showAllCompany/').then(function (resp){
      // _this.product = resp.data.records
      // console.log(resp.data)
      _this.dataGet.companyIdOption = resp.data
    })
    axios.get(this.api.URL+'version/VUEshowVersionByCompanyId/'+_this.goodsVO.companyId).then(function (resp){
      // _this.product = resp.data.records
      _this.dataGet.versionIdOption = resp.data

    })
    axios.get(this.api.URL+'user/findall/').then(function (resp){
      // _this.product = resp.data.records
      _this.dataGet.userIdOption = resp.data
      _this.dataGet.userIdOption.unshift(_this.usernull)
      // console.log(resp.data)

    })
    _this.ruleForm.id = _this.goodsVO.id
    _this.ruleForm.companyId = _this.goodsVO.companyId
    _this.ruleForm.versionId = _this.goodsVO.versionId
    _this.ruleForm.userId = _this.goodsVO.userId
    _this.ruleForm.count = _this.goodsVO.count
  },






  methods: {
    resetForm(ruleForm) {

      const _this = this

      axios.get(this.api.URL+'version/VUEshowVersionByCompanyId/'+_this.goodsVO.companyId).then(function (resp){
        // _this.product = resp.data.records
        _this.dataGet.versionIdOption = resp.data

      })
      _this.ruleForm.companyId = _this.goodsVO.companyId
      _this.ruleForm.versionId = _this.goodsVO.versionId
      _this.ruleForm.count = _this.goodsVO.count
      _this.ruleForm.userId = _this.goodsVO.userId
    },
    clear(){
      const _this = this
      _this.dataGet.versionIdOption = {}
      _this.ruleForm.versionId = ''
    },

    search(){
      const _this = this
      _this.dataGet.versionIdOption = {}
      _this.ruleForm.versionId = ''
      // axios.get('http://localhost:8181/version/VUEgetAll/').then(function (resp){
      axios.get(this.api.URL+'version/VUEshowVersionByCompanyId/'+this.ruleForm.companyId).then(function (resp){
        // _this.product = resp.data.records
        _this.dataGet.versionIdOption = resp.data
      })
    },
    submitForm(formName) {
      const _this = this
      this.$refs[formName].validate((valid) => {
        if (valid){
          this.$confirm('此操作将永久修改文件，不可撤回, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            console.log(this.ruleForm)
            axios.post(this.api.URL+'goods/updateByAdmin',this.ruleForm).then((resp)=>{

              if(resp.data == "ok"){
                this.$message({
                  type: 'success',
                  message: '修改成功!'
                });
                this.$router.push({
                  path:"/goodsAll",
                  //这里传的是一个对象
                })
              }else {
                this.$message({
                  type: 'error',
                  message: '修改失败'
                });
              }
            })

          }).catch(() => {

            this.$message({
              type: 'info',
              message: '已取消修改'
            });
          });
        }
      })
    }
  }
}
</script>
