<template>

  <div>

    <div >



      <div v-for="item in company"  style="padding-bottom:20px;padding-top:20px;border-bottom: 1px double #6B8E23; width:1100px">

        <div style="width: 120px;display:table-cell;font-size: 20px;font-weight: bold;color: darkslateblue">{{item.name}}</div>
        <div style="width: 200px;display:table-cell;font-size: 15px;color: darkslateblue">手机号：  {{item.phoneNumber}}</div>
        <div style="width: 220px;display:table-cell;font-size: 19px;color: darkslateblue">总金额： {{item.alltotal}}</div>
        <div style="width: 220px;display:table-cell;font-size: 19px;color: darkslateblue">已支付： {{item.settlement}}</div>
        <div style="width: 220px;display:table-cell;font-size: 19px;color: darkslateblue">未支付： {{(item.alltotal-item.settlement).toFixed(2)}}</div>

        <div style="display:table-cell;color: darkslateblue">
          <el-button  style="display:table-cell;"type="success" @click="toprint(item)" >详情页面</el-button>
        </div>


        <div  style="margin-left: 10px;margin-top:20px ;padding-top:10px">
          <!--             style="margin-top:20px ;padding-top:10px;color: cornflowerblue;border-top: 1px dashed cornflowerblue;"-->

          <div v-for="version in item.versionCompanyList" >
            <!--            <div style="width: 500px;display:table-cell;font-size: 18px;font-weight: bold;color: cornflowerblue">{{version.versionName}}</div>-->
            <!--            <div style="width: 200px;display:table-cell;font-size: 17px;color: cornflowerblue">总件数： {{version.total}}</div>-->


            <el-collapse v-model="activeNames" @change="handleChange" >

              <el-collapse-item name="1">
                <template slot="title">
                  <span style="width: 180px">{{ version.versionName }}</span><span style="width: 200px">价格：{{version.price}}</span><span style="width: 200px">工人工资：{{version.wage}}</span><span style="width: 120px">总件数：{{version.total}}</span>
                </template>




                <div v-if="version.versionchangeList != 0">

                  <el-table
                      :data="version.versionchangeList"
                      border
                      style="width: 100%;margin-left: 50px" >
<!--                    <el-table-column-->
<!--                        fixed-->
<!--                        prop="id"-->
<!--                        label="单号"-->
<!--                        width="120">-->
<!--                    </el-table-column>-->
                    <el-table-column
                        prop="price"
                        label="修改前价格"
                        width="150">
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        label="修改时总件数"
                        width="170">
                    </el-table-column>
                    <el-table-column
                        prop="changeTotal"
                        label="修改前的价格所计件数"
                        width="190">
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="修改时间"
                        width="190" >
                    </el-table-column>


                  </el-table>
                </div>


              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>


      <div style="padding-bottom:20px;padding-top:20px;border-bottom: 1px double #6B8E23; width:1100px">

        <div style="width: 120px;display:table-cell;font-size: 20px;font-weight: bold;color: darkred">总计</div>
        <div style="width: 220px;display:table-cell;font-size: 19px;color: darkred">{{allofmoney}}</div>
      </div>
    </div>


  </div>


</template>

<script>
import axios from "axios";

export default {
  name: "CompanyInformationDay",

  methods:{


    toprint(item){
      this.$router.push({
        path:"/companyPrint",
        query:{
          company:item
        }
      })
    },
    handleChange(val) {
      // console.log(val);
    },
    printNow(row){
      this.$router.push({
        path:"/goodsPrint",
        query:{
          goodsVO:row
        }
      })
    },

  },

  data() {
    return {
      value:'',
      activeNames: ['1'],
      company:'',
      allofmoney:0,
    }
  },

  created() {
    const _this = this

    axios.get(this.api.URL+"company/AllInformation/").then(function (resp){
      console.log(resp.data)
      _this.company = resp.data
      for(var i = 0; i < resp.data.length ; i++){
        _this.allofmoney = _this.allofmoney + resp.data[i].alltotal;
      }
      console.log(_this.allofmoney)
    })
  }
}
</script>

<style scoped>
/deep/ .el-collapse-item__header{
  font-size: 18px;
  color: cornflowerblue;
}

</style>