<template>

  <div>
    <div v-for="item in user"  style="padding-bottom:20px;padding-top:20px;border-bottom: 1px double #6B8E23; width:1100px">

      <el-collapse v-model="activeNames" @change="handleChange" >

        <el-collapse-item name="1">
          <template slot="title">
            <span style="width: 180px">{{ item.name }}</span><span style="width: 260px">手机号：{{item.phoneNumber}}</span><span style="width: 200px">总金额： {{item.total}}</span><span style="width: 200px">已支付： {{item.settlement}}</span><span style="width: 200px">未支付： {{(item.total-item.settlement).toFixed(2)}}</span>
          </template>




          <div v-if="item.payuserList != 0">

            <el-table
                :data="item.payuserList"
                border
                style="width: 100%;margin-left: 50px" >
              <!--                    <el-table-column-->
              <!--                        fixed-->
              <!--                        prop="id"-->
              <!--                        label="单号"-->
              <!--                        width="120">-->
              <!--                    </el-table-column>-->
              <el-table-column
                  prop="money"
                  label="入账金额"
                  width="200">
              </el-table-column>
              <el-table-column
                  prop="dataTime"
                  label="入账时间"
                  width="260">
              </el-table-column>



            </el-table>
          </div>
        </el-collapse-item>
      </el-collapse>

    </div>
  </div>


</template>

<script>
import axios from "axios";

export default {
  name: "UserInformationAll",
  methods:{

  },
  data() {
    return {
      value:'',
      activeNames: ['1'],
      user:'',
    }
  },
  created() {
    const _this = this

    axios.get(this.api.URL+"user/AllInformation/").then(function (resp){
      console.log(resp.data)
      _this.user = resp.data
    })
  }
}
</script>

<style scoped>
/deep/ .el-collapse-item__header{
  font-size: 18px;
  color: cornflowerblue;
}
</style>