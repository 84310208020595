<template>
  <div>

    <div >

      <div>
        <el-date-picker
            v-model="value"
            type="date"
            placeholder="选择日期">
        </el-date-picker>

        <el-button type="primary" @click="submit()">搜索</el-button>
      </div>


      <div v-for="item in user"  style="padding-bottom:20px;padding-top:20px;border-bottom: 1px double #6B8E23; width:1100px">

        <div style="width: 150px;display:table-cell;font-size: 20px;font-weight: bold;color: darkslateblue">{{item.name}}</div>
        <div style="width: 400px;display:table-cell;font-size: 15px;color: darkslateblue">手机号：  {{item.phoneNumber}}</div>
        <div style="width: 200px;display:table-cell;font-size: 19px;color: darkslateblue">总件数： {{item.totalcount}}</div>

        <div>


          <div v-for="company in item.companyCountList" style="padding-left:30px;margin-top:20px ;padding-top:20px;color: cornflowerblue;">

            <div style="padding-bottom: 20px">
              <div style="width: 140px;display:table-cell;font-size: 18px;font-weight: bold;color: cornflowerblue">{{company.name}}</div>
              <div style="width: 380px;display:table-cell;font-size: 14px;color: cornflowerblue">手机号：{{company.phoneNumber}}</div>
              <div style="width: 200px;display:table-cell;font-size: 17px;color: cornflowerblue">总件数： {{company.total}}</div>

            </div>



            <div v-for="version in company.versionCompanyList" >
              <el-collapse v-model="activeNames" @change="handleChange" >

                <el-collapse-item>
                  <template slot="title">
                    <span style="width: 550px">{{ version.versionName }}</span><span>总件数：{{version.total}}</span>
                  </template>




                  <div>

                    <el-table
                        :data="version.goodsVOList" border style="width: 100%" >
                      <el-table-column
                          fixed
                          prop="id"
                          label="单号"
                          width="120">
                      </el-table-column>
                      <el-table-column
                          prop="count"
                          label="件数"
                          width="120">
                      </el-table-column>
                      <el-table-column
                          prop="userName"
                          label="计件员工"
                          width="120">
                      </el-table-column>
                      <el-table-column
                          prop="createTime"
                          label="创建时间"
                          width="170">
                      </el-table-column>
                      <el-table-column
                          prop="finishTime"
                          label="员工计件时间"
                          width="170" >
                      </el-table-column>
                      <el-table-column
                          prop="updateTime"
                          label="修改时间"
                          width="170">
                      </el-table-column>




                      <el-table-column
                          label="操作"
                          width="120">
                        <template slot-scope="scope">
                          <el-button @click="printNow(scope.row)" type="text" size="small">打印</el-button>
                          <el-button @click="editorproduct(scope.row)" type="text" size="small">修改</el-button>
                          <el-button @click="deleteProduct(scope.row)" type="text" size="small">删除</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>


                </el-collapse-item>
              </el-collapse>

            </div>


          </div>
        </div>
      </div>


    </div>


  </div>


</template>

<script>
import axios from "axios"


export default {
  name: "UserInformationDay",
  methods:{
    submit(){
      const _this = this

      if(!_this.value){
        alert("请选择时间")
      }
      let yy = _this.value.getFullYear()
      let mm = _this.value.getMonth() + 1
      let dd = _this.value.getDate()
      const createTime = yy + '-' + mm + '-' + dd
      // console.log(date)


      axios.get(this.api.URL+'user/UserDistintByCreateTime/'+createTime).then(function (resp){

        _this.user = resp.data
        console.log(resp.data)
      })
    },

    handleChange(val) {
      // console.log(val);
    },
    printNow(row){
      this.$router.push({
        path:"/goodsPrint",
        query:{
          goodsVO:row
        }
      })
    },
    editorproduct(row) {
      this.$router.push({
        path:"/goodsChange",
        query:{
          goodsVO:row
        }
      })
    },
    deleteProduct(row){
      const _this = this
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const __this = this
        // alert(row.id)
        axios.get(this.api.URL+'goods/delete/'+row.id).then(function (resp){
          if(resp.data == "ok"){
            window.location.reload()
            __this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            __this.$message({
              type: 'error',
              message: '删除失败!'
            });
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
  },
  created(){
    const _this = this
    var time = new Date();
    let yy = time.getFullYear()
    let mm = time.getMonth() + 1
    let dd = time.getDate()
    const createTime = yy + '-' + mm + '-' + dd
    // var a = '2022-08-15'
    axios.get(this.api.URL+'user/UserDistintByCreateTime/'+createTime).then(function (resp){
      _this.user = resp.data
      console.log(resp.data)
    })
  },
  data(){
    return{
      user:[],
      value:'',
      activeNames: [],
    }
  },


}
</script>

<style scoped>

</style>