<template>
  <el-container>
    <el-form>
      <div style="width: 800px ; text-align: center;font-size: 22px">
        新增服饰部件信息
      </div>


      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" style="margin-left: 100px" >
        <el-form-item label="部件名称" prop="name">
          <el-input v-model="ruleForm.name" style="width: 230px" ></el-input>
        </el-form-item>
        <el-form-item label="克重" prop="number">
          <el-input v-model.number="ruleForm.number" style="width: 230px" ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">添加</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-form>
  </el-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      companyId:'',
      ruleForm:{
        versionId:'',
        name: '',
        number: ''
      },

      rules: {
        name:[
          { required: true, message: '请输入部件名称', },
        ],

        number: [
          { required: true, message: '请输入计件数量', },
          { type: 'number' }
        ]
      }
    }
  },



  created(){
    const _this = this
    _this.ruleForm.versionId = sessionStorage.getItem("versionId")
    // console.log(_this.ruleForm.id)
    _this.companyId = sessionStorage.getItem("companyId")
    // console.log(_this.companyId)
  },





  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },



    submitForm(formName) {
      const _this = this
      this.$refs[formName].validate((valid) => {
        if (valid){
          // console.log(this.ruleForm)
          axios.post(this.api.URL+'attribute/add',this.ruleForm).then((resp)=>{
            if(resp.data == "ok"){
              this.$message({
                type: 'success',
                message: '添加成功!'
              });
              // console.log(resp.data)

              sessionStorage.setItem("companyId" , _this.companyId)
              this.$router.push({
                path:"/versionManage",
              })
            }else {
              this.$alert('添加失败', '失败', {
                confirmButtonText: '确定',
              });
            }
          })


        }
      })
    }
  }
}
</script>

<style scoped>

</style>