<template>

  <div>
<!--    <div style="text-align: center;margin-bottom:20px">-->
<!--      <el-form :rules="rules" :model="ruleForm"  ref="ruleForm"   style="position: absolute; ">-->
<!--        <el-form-item prop="id" >-->
<!--          <el-input type="text" v-model.number="ruleForm.id" placeholder="请输入单号进行搜索"style="width: 230px" autocomplete="off"></el-input><el-button type="primary" @click="submitForm('ruleForm')">搜索</el-button>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <el-date-picker-->
<!--          v-model="value"-->
<!--          type="date"-->
<!--          placeholder="选择日期">-->
<!--      </el-date-picker>-->

<!--      <el-button type="primary" @click="submit()">搜索</el-button>-->
<!--    </div>-->
    <div>
      <el-button type="success" @click="addCompany">点击添加服装厂</el-button>

      <el-table
          :data="companyVO"
          border
          style="width: 100%;font-size: large" >
        <el-table-column
            fixed
            prop="phoneNumber"
            label="手机号"
            width="150">
        </el-table-column>
        <el-table-column
            prop="name"
            label="厂家名"
            width="140">
        </el-table-column>
        <el-table-column
            label="操作"
            width="170">
          <template slot-scope="scope" >

            <el-button @click="addVersion(scope.row)" style="font-size: 18px"type="text" size="small">添加服装版型</el-button>
            <el-button @click="editorCompany(scope.row)" style="font-size: 18px"type="text" size="small">修改</el-button>
            <el-button @click="deleteCompany(scope.row)" style="font-size: 17px"type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column >
          <template slot-scope="scope">
            <el-table
                :data="scope.row.versionList"
                border
                style="width: 100%;font-size: 17px" >>
              <el-table-column
                  prop="versionName"
                  label="服装版本"
                  width="140">
              </el-table-column>
              <el-table-column
                  prop="price"
                  label="价格"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="wage"
                  label="工人工资"
                  width="120">
              </el-table-column>
              <el-table-column
                  label="操作"
                  width="150">
                <template slot-scope="scope1">

                  <el-button @click="versionManage(scope.row,scope1.row)"style="font-size: 16px"type="text" size="small">版本信息管理</el-button>
                  <el-button @click="editorVersion(scope1.row)"style="font-size: 16px" type="text" size="small">修改</el-button>
                  <el-button @click="deleteVersion(scope1.row)"style="font-size: 15px" type="text" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>

</template>

<script>
import axios from 'axios'
export default {
  methods: {


    versionManage(row,row1){

      sessionStorage.setItem("companyId",row.id)

      sessionStorage.setItem("versionId",row1.id)
      this.$router.push({

        path:"/versionManage",
        query:{
          company:row,
          version:row1
        },
      })
    },


    addCompany(){
      this.$router.push({
        path:"/companyNew",

      })
    },
    addVersion(row){

      sessionStorage.setItem("companyId",row.id)
      // console.log(row.id)
      this.$router.push({
        path:"/versionNew",
        query:{
          company:row
        },
      })
    },
    // submit(){
    //   const _this = this
    //
    //   if(!_this.value){
    //     alert("请选择时间")
    //   }
    //   let yy = _this.value.getFullYear()
    //   let mm = _this.value.getMonth() + 1
    //   let dd = _this.value.getDate()
    //   const createTime = yy + '-' + mm + '-' + dd
    //   // console.log(date)
    //
    //
    //   axios.get('http://localhost:8181/goods/getGoodsByCreateTime/'+createTime).then(function (resp){
    //     _this.goodsVO = resp.data
    //     _this.total = 0
    //   })
    // },


    // submitForm(formName) {
    //   const _this = this
    //   this.$refs[formName].validate((valid) => {
    //     if (valid){
    //       const that = this
    //       axios.post('http://localhost:8181/goods/VUEfindGoodsByIdList',this.ruleForm).then(function (resp){
    //         if(resp.data != ""){
    //           _this.goodsVO = resp.data
    //           _this.total = 0
    //         }else {
    //           alert("不存在数据")
    //         }
    //       })
    //     }
    //   })
    // },


    // editor(row) {
    //   this.$router.push({
    //     path:"/changeGoods",
    //     query:{
    //       goodsVO:row
    //     }
    //   })
    // },
    editorCompany(row){
      const _this = this
      // console.log(row)
      // sessionStorage.setItem("CompanyChange",row)
      _this.$router.push({
        path:"/companyChange",
        //这里传的是一个对象
        query:{
          companyChange:row
        },
      })
    },

    editorVersion(row){
      const _this = this
      console.log(row)
      sessionStorage.setItem("CompanyChange",row)
      _this.$router.push({
        path:"/versionChange",
        //这里传的是一个对象
        query:{
          versionChange:row
        },
      })
    },

    deleteVersion(row){
      const _this = this
      // console.log(row)
      _this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const __this = this
        // alert(row.id)
        axios.get(this.api.URL+'version/VUEdelVersion/'+row.id).then(function (resp){
          if(resp.data == "ok"){
            window.location.reload()
            __this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            _this.$confirm('存在关联信息，请先删除此服装版本的计件信息后再删除此服装版本', '提示', {
              confirmButtonText: '确定',
              type: 'warning'
            })
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });


    },

    deleteCompany(row){
      const _this = this
      // console.log(row)
      if(row.versionList.length != 0){
        this.$confirm('存在关联信息，请先删除此服装厂的服装版本信息后再删除服装厂', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
      }else {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const __this = this
          // alert(row.id)
          axios.get(this.api.URL+'company/VUEdelCompany/'+row.id).then(function (resp){
            if(resp.data == "ok"){
              window.location.reload()
              __this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }else{
              __this.$message({
                type: 'error',
                message: '删除失败!'
              });
            }
          })

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },

    // page(currentPage){
    //   const _this = this
    //   axios.get('http://localhost:8181/goods/page/'+currentPage +"/" +8).then(function (resp){
    //     _this.goods = resp.data.records
    //   })
    //
    //   axios.get('http://localhost:8181/goods/pageDESC/'+currentPage +"/" +8).then(function (resp){
    //     _this.goodsVO = resp.data
    //   })
    // }
  },

  data() {
    return {

      companyVO:[],
      // total: null,
      // goods: [],
      // goodsVO:[],
      // version:[],
      // company:[],
      // user:[],
      // size:8,
      //
      // ruleForm:{
      //   id:'',
      // },
      //
      // rules: {
      //   id:[
      //     { required: true, message: '请输入单号', trigger:"change"},
      //     { type: 'number',message: '单号为数字类型',trigger:"change" }
      //   ],
      // },
      //
      // value:'',

    }
  },


  created() {


    //这里的this.product指的是data中的product
    //this.product
    const _this = this
    axios.get(this.api.URL+'company/showAllCompanyVO').then(function (resp){
      _this.companyVO = resp.data
      // console.log(resp.data)
    })

    // axios.get('http://localhost:8181/goods/page/1/8').then(function (resp){
    //   _this.goods = resp.data.records
    //   _this.total = resp.data.total
    // })
    // axios.get('http://localhost:8181/goods/pageDESC/1/8').then(function (resp){
    //   _this.goodsVO = resp.data
    // })


  }
}
</script>

<style scoped>

</style>