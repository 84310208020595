<template>
  <div style="width: 500px;background: #B3C0D1;margin-left: 100px">
    <div style="font-size: larger">
      <span style="font-weight: bold">服装厂名称:&nbsp</span><span>{{versionVO.companyName}}</span>
      <p></p>
      <span style="font-weight: bold">服装版本:&nbsp&nbsp&nbsp&nbsp</span><span>{{versionVO.versionName}}</span>
    </div>
    <el-table
    :data="versionAttribute"
    style = "width:100%">
      <el-table-column
          prop="name"
          label="部件名称"
          width="180">
      </el-table-column>
      <el-table-column
          prop="number"
          label="克重"
          width="180">
      </el-table-column>
      <el-table-column
          label="操作"
          width="120">
        <template slot-scope="scope">

          <el-button @click="editor(scope.row)" type="text" size="small">修改</el-button>
          <el-button @click="deleteAttribute(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: center;margin:30px">
      <el-button type="primary" @click="add()">新增服装信息</el-button>

      <el-button type="success" @click="toprint()">打印</el-button>
    </div>


  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VersionManage",
  methods: {
    toprint(){
      const _this = this
      this.$router.push({
        path:"/versionPrint",
        query:{
          versionVO:_this.versionVO,
          versionAttribute:_this.versionAttribute
        }
      })
    },
    editor(row){
      console.log(row)
      this.$router.push({
        path:"/versionAttributeUpdate",
        query:{
          attribute:row
        }
      })
    },


    add(){
      const _this = this
      sessionStorage.setItem("versionId",_this.version.id)

      sessionStorage.setItem("companyId",_this.companyId)
      this.$router.push({
        path:"/versionAttributeAdd",
        // query:{
        //   versionId:_this.version.id
        // }
      })
    },

    deleteAttribute(row){
      const _this = this
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const __this = this
        // alert(row.id)
        axios.get(this.api.URL+'attribute/delete/'+row.id).then(function (resp){
          if(resp.data == "ok"){
            window.location.reload()
            __this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            __this.$message({
              type: 'error',
              message: '删除失败!'
            });
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }

  },
  created() {
    const _this = this
    _this.companyId = sessionStorage.getItem("companyId")
    _this.versionId = sessionStorage.getItem("versionId")
    // console.log(_this.companyId)
    axios.get(this.api.URL+'company/VUEshowCompany/'+_this.companyId).then(function (resp){
      _this.company.id = resp.data.id;
      _this.company.name = resp.data.name;
      _this.versionVO.companyName = resp.data.name;
    })
    axios.get(this.api.URL+'version/VUEshowVersionById/'+_this.versionId).then(function (resp){
      // console.log(resp.data)
      _this.version.id = resp.data.id;
      _this.version.versionName = resp.data.versionName

      _this.versionVO.id = resp.data.id
      _this.versionVO.versionName = resp.data.versionName
    })
    // console.log(_this.company)
    // _this.version = this.$route.query.version



    // console.log(_this.versionVO)

    axios.get(this.api.URL+'attribute/VUEShowAllByVersionId/'+_this.versionId).then(function (resp){
      console.log(resp.data)
      _this.versionAttribute = resp.data
    })

  },


  data() {

    return {
      versionId:'',
      companyId:'',
      company:{
        id:'',
        name:'',
      },
      version: {
        id:'',
        versionName:'',
      },
      versionAttribute:[],
      versionVO:{
        id:'',
        companyName:'',
        versionName:'',
        wage:'',
      }
    }
  }
}
</script>

<style scoped>

</style>