<template>

  <div>
    <div style="text-align: center;margin-bottom:20px">
      <el-form :rules="rules" :model="ruleForm"  ref="ruleForm"   style="position: absolute; ">
        <el-form-item prop="id" >
          <el-input type="text" v-model.number="ruleForm.id" placeholder="请输入单号进行搜索"style="width: 230px" autocomplete="off"></el-input><el-button type="primary" @click="submitForm('ruleForm')">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-date-picker
          v-model="value"
          type="date"
          placeholder="选择日期">
      </el-date-picker>

      <el-button type="primary" @click="submit()">搜索</el-button>
      <el-button tpye="text" @click="gotoSearch()">详细搜索</el-button>
    </div>
    <div>

      <el-table
          :data="goodsVO"
          border
          style="width: 100%" >
        <el-table-column
            fixed
            prop="id"
            label="单号"
            width="120">
        </el-table-column>
        <el-table-column
            prop="companyName"
            label="厂家名"
            width="120">
        </el-table-column>
        <el-table-column
            prop="versionName"
            label="服装型号"
            width="120">
        </el-table-column>
        <el-table-column
            prop="count"
            label="件数"
            width="80">
        </el-table-column>
        <el-table-column
            prop="userName"
            label="计件员工"
            width="100">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            width="170">
        </el-table-column>
        <el-table-column
            prop="finishTime"
            label="员工计件时间"
            width="170" >
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="修改时间"
            width="170">
        </el-table-column>




        <el-table-column
            label="操作"
            width="120">
          <template slot-scope="scope">

            <el-button @click="printNow(scope.row)" type="text" size="small">打印</el-button>
            <el-button @click="editor(scope.row)" type="text" size="small">修改</el-button>
            <el-button @click="deleteProduct(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          layout="prev, pager, next"
          :page-size="size"
          :total="total"
          @current-change="page">
      </el-pagination>
    </div>

  </div>

</template>

<script>
import axios from 'axios'
export default {
  methods: {

    gotoSearch(){
      this.$router.push({
        path:"/search",
      })
    },
    submit(){
      const _this = this

      if(!_this.value){
        alert("请选择时间")
      }
      let yy = _this.value.getFullYear()
      let mm = _this.value.getMonth() + 1
      let dd = _this.value.getDate()
      const createTime = yy + '-' + mm + '-' + dd
      // console.log(date)


      axios.get(this.api.URL+'goods/getGoodsByCreateTime/'+createTime).then(function (resp){
        _this.goodsVO = resp.data
        _this.total = 0
      })
    },


    submitForm(formName) {
      const _this = this
      this.$refs[formName].validate((valid) => {
        if (valid){
          const that = this
          axios.post(this.api.URL+'goods/VUEfindGoodsByIdList',this.ruleForm).then(function (resp){
            if(resp.data != ""){
              _this.goodsVO = resp.data
              _this.total = 0
            }else {
              alert("不存在数据")
            }
          })
        }
      })
    },
    printNow(row){
      this.$router.push({
        path:"/goodsPrint",
        query:{
          goodsVO:row
        }
      })
    },
    editor(row) {
      this.$router.push({
        path:"/goodsChange",
        query:{
          goodsVO:row
        }
      })
    },
    deleteProduct(row){
      const _this = this
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const __this = this
        // alert(row.id)
        axios.get(this.api.URL+'goods/delete/'+row.id).then(function (resp){
          if(resp.data == "ok"){
            window.location.reload()
            __this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            __this.$message({
              type: 'error',
              message: '删除失败!'
            });
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    page(currentPage){
      const _this = this
      axios.get(this.api.URL+'goods/page/'+currentPage +"/" +8).then(function (resp){
        _this.goods = resp.data.records
        _this.total = resp.data.total

      })

      axios.get(this.api.URL+'goods/pageDESC/'+currentPage +"/" +8).then(function (resp){
        _this.goodsVO = resp.data

      })
    }
  },

  data() {
    return {

      total: null,
      goods: [],
      goodsVO:[],
      version:[],
      company:[],
      user:[],
      size:8,

      ruleForm:{
        id:'',
      },

      rules: {
        id:[
          { required: true, message: '请输入单号', trigger:"change"},
          { type: 'number',message: '单号为数字类型',trigger:"change" }
        ],
      },

      value:'',

    }
  },


  created() {


    //这里的this.product指的是data中的product
    //this.product
    const _this = this

    axios.get(this.api.URL+'goods/page/1/8').then(function (resp){
      _this.goods = resp.data.records
      _this.total = resp.data.total
    })
    axios.get(this.api.URL+'goods/pageDESC/1/8').then(function (resp){
      _this.goodsVO = resp.data
    })


  }
}
</script>

<style scoped>

</style>