<template>
  <el-container>
    <el-form>
      <div style="width: 800px ; text-align: center;font-size: 22px">
        新增服装厂
      </div>


      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" style="margin-left: 100px" >
        <el-form-item label="手机号" prop="phoneNumber">
          <el-input v-model="ruleForm.phoneNumber" style="width: 230px" ></el-input>
        </el-form-item>
        <el-form-item label="服装厂名" prop="name">
          <el-input v-model="ruleForm.name" style="width: 230px" ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">添加</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-form>
  </el-container>








</template>

<script>
import axios from "axios";

export default {
  data() {
    var checkPhoneNumber = (rule,value,callback) =>{
      if (!value) {
        callback(new Error('手机号不能为空'));
      }else if (value.length!=11){
        callback(new Error('请输入11位数手机号'));
      }else {
        callback();
      }

    }
    return {
      ruleForm:{
        phoneNumber: '',
        name: '',
      },

      rules: {
        phoneNumber:[
          { validator: checkPhoneNumber, trigger: 'blur' }

        ],
        name:[
          { required: true, message: '请输入服装厂名', },
        ],
      }
    }
  },




  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },



    submitForm(formName) {
      const _this = this
      this.$refs[formName].validate((valid) => {
        if (valid){
          // console.log(this.ruleForm)
          axios.post(this.api.URL+'company/VUEaddCompany',this.ruleForm).then((resp)=>{
            if(resp.data == "ok"){
              this.$message({
                type: 'success',
                message: '添加成功!'
              });
              // console.log(resp.data)
              this.$router.push({
                path:"/companyManage",
                //这里传的是一个对象
                query:{
                  goodsVO:resp.data
                },
              })
            }else {
              this.$alert('添加失败', '失败', {
                confirmButtonText: '确定',
              });
            }
          })


        }
      })
    }
  }
}
</script>
