import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import GoodsAll from "@/views/GoodsAll";
import GoodsNew from "@/views/GoodsNew";
import Index from "@/views/index";
import GoodsPrint from "@/views/GoodsPrint";
import Main from "@/views/main";
import Contact from "@/views/contact";
import GoodsChange from "@/views/GoodsChange";
import CompanyManage from "@/views/CompanyManage";
import CompanyNew from "@/views/CompanyNew";
import CompanyChange from "@/views/CompanyChange";
import VersionNew from "@/views/VersionNew";
import VersionChange from "@/views/VersionChange";
import VersionManage from "@/views/VersionManage";
import VersionAttributeAdd from "@/views/VersionAttributeAdd";
import VersionAttributeUpdate from "@/views/VersionAttributeUpdate";
import VersionPrint from "@/views/VersionPrint";
import Search from "@/views/Search";
import CompanyInformationDay from "@/views/CompanyInformationDay";
import CompanyInformationAll from "@/views/CompanyInformationAll";
import CompanyPrint from "@/views/CompanyPrint";
import UserInformationDay from "@/views/UserInformationDay";
import UserInformationAll from "@/views/UserInformationAll";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name:'首页',
    component: Main
  },
  {
    path: '/contact',
    name:'联系我们',
    component: Contact
  },
  {
    path:"/navigation",
    name:"计件信息",
    component: Index,
    //什么都不写跳转到allGoods
    redirect:"/goodsAll",
    show:true,
    children:[
      {
        path:"/goodsAll",
        name:"查询计件",
        component: GoodsAll,
      },{
        path:"/goodsNew",
        name:"新增计件",
        component: GoodsNew,
      },
    ]
  },
  {
    path:"/navigation",
    name:"服装厂管理",
    component: Index,
    //什么都不写跳转到allGoods
    // redirect:"/allGoods",
    show:true,
    children:[
      {
        path:"/companyManage",
        name:"服装信息管理",
        component: CompanyManage,
      },{
        path:"/companyInformationDay",
        name:"当日账单",
        component: CompanyInformationDay,
      },{
        path:'/companyInformationAll',
        name:'总账单',
        component: CompanyInformationAll
      }
    ]
  },


  {
    path:"/navigation",
    name:"员工信息管理",
    component: Index,
    //什么都不写跳转到allGoods
    // redirect:"/allGoods",
    show:true,
    children:[
      {
        path:'/userInformationDay',
        name:'员工当日计件信息',
        component:UserInformationDay
      },
      {
        path: '/userInformationAll',
        name:'员工总账单',
        component: UserInformationAll
      }
    ]
  },


  {
    path:"/navigation",
    // name:"公司管理",
    component: Index,
    //什么都不写跳转到allGoods
    // redirect:"/allGoods",
    show:false,
    children:[
      {
        path:'/companyPrint',
        name:"详情打印",
        component: CompanyPrint,
      },
      {
        path: '/search',
        name:'详细查询',
        component: Search
      },
      {
        path: '/versionPrint',
        name:'打印信息',
        component: VersionPrint
      },
      {
        path: '/versionAttributeUpdate',
        name:"修改服饰部件信息",
        component: VersionAttributeUpdate
      },
      {
        path: '/versionAttributeAdd',
        name:"添加服饰部件信息",
        component: VersionAttributeAdd
      },

      {
        path:'/versionManage',
        name:'服装信息管理',
        component: VersionManage
      },
      {
        path:'/versionChange',
        name:'修改服装型号信息',
        component: VersionChange
      },
      {
        path: '/versionNew',
        name:'新增服装型号信息',
        component: VersionNew
      },
      {
        path: "/companyChange",
        name:'修改服装厂信息',
        component: CompanyChange
      },
      {
        path: '/companyNew',
        name:'新增服装厂',
        component: CompanyNew
      },
      {
        path: '/goodsPrint',
        name:"打印计件码",
        component: GoodsPrint,
      },
      {
        path:'/goodsChange',
        name:'修改计件信息',
        component: GoodsChange
      }
    ]
  },


  {
    path: '/login',
    name: 'login',
    component: Login
  },





]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// 导航守卫、路由守卫、路由拦截
router.beforeEach((to,from,next) => {
  //验证token，只有存在token的时候才能跳转到内容页
  let token = sessionStorage.getItem("name");
  if(token || to.path == "/login" || to.path == "/" || to.path == "/contact"){
    next();
  }else {
    next("/login")
  }
})



export default router
