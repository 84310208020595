<template >


  <div style="width: 100%;height: 100%;background: lightgray">
    <div class="main">
      <div style="text-align: center;font-size: 30px;margin-bottom: 70px" >
        管理员登录
      </div>
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="40px" class="demo-ruleForm" >
        <el-form-item label="账号" prop="phoneNumber" >
          <el-input type="text" v-model="ruleForm.phoneNumber" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button style="width: 200px;margin-left: 65px" type="primary" @click="submitForm('ruleForm')">登录</el-button>
        </el-form-item>
      </el-form>

      <div style="text-align: center; ">
        <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2022028997号-1</a>

        &nbsp;

        <a href="https://beian.mps.gov.cn/#/query/webSearch"><img src="../assets/logo01.png"width="15px">鲁公网安备37090202001435号</a>

      </div>
    </div>

  </div>

</template>

<script>


import axios from "axios";


export default {
  data() {
    var checkPhoneNumber = (rule,value,callback) =>{
      if (!value) {
        callback(new Error('账号不能为空'));
      }else if (value.length!=11){
        callback(new Error('请输入11位数手机号'));
      }else {
        callback();
      }

    }



    var checkPassword = (rule,value,callback) =>{
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value.length<6) {
        callback(new Error('密码不能低于6位数'));
      }else {
        callback();
      }
    };




    return {
      ruleForm: {
        phoneNumber: '',
        password: '',
      },
      rules: {
        phoneNumber: [
          { validator: checkPhoneNumber, trigger: 'blur' }
        ],
        password: [
          { validator: checkPassword, trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    
    submitForm(formName) {
      const _this = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post(_this.api.URL+'admin/VUElogin',_this.ruleForm).then(function (resp){
            console.log(_this.api.URL+'admin/VUElogin',_this.ruleForm)
            console.log(resp)
            if(resp.data != ""){
              // axios.post()
              // console.log(resp.data.name)
              sessionStorage.setItem("name",resp.data.name)
              _this.$router.push('/goodsAll')
            }else {
              _this.$alert('账号或密码错误', '登录失败', {
                confirmButtonText: '确定',

              });
            }
          })

        } else {
          return false;
        }
      });
    },
  }
}
</script>
<style scoped>
.main{
  position:absolute;
  top:50%;
  left:50%;
  width:440px;
  height:240px;
  margin-top:-220px;
  margin-left:-320px;
  border-radius:20px;
  padding-left: 100px;
  padding-right:100px;
  padding-bottom:100px;
  padding-top: 60px;
  background: white;
  /*line-height:30px;*/
  /*font-size:16px;*/
  /*text-align:center;*/
}

</style>
