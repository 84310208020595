<template>
  <el-container>
    <el-form>
      <div style="width: 800px ; text-align: center;font-size: 22px">
        新增服装版本
      </div>


      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" style="margin-left: 100px" >
        <el-form-item label="版型名称" prop="versionName">
          <el-input v-model="ruleForm.versionName" style="width: 230px" ></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input-number v-model.number="ruleForm.price":precision="2" style="width: 230px" ></el-input-number>
        </el-form-item>
        <el-form-item label="计件工资" prop="price">
          <el-input-number v-model.number="ruleForm.wage":precision="2" style="width: 230px" ></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">添加</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <span>注意：请再次核实输入信息正确，如日后需要修改价格和计件工资，将不会对以前所记录的信息进行修改</span>
      <p></p>
      <span>&nbsp &nbsp &nbsp &nbsp &nbsp &nbsp</span><span>以后所显示的服装版本信息均为修改后的信息</span>
    </el-form>
  </el-container>








</template>

<script>
import axios from "axios";

export default {
  created() {
    this.ruleForm.wage = 2;
    this.ruleForm.companyId = sessionStorage.getItem("companyId")
  },
  data() {

    return {
      ruleForm:{
        companyId:'',
        versionName: '',
        price: '',
        wage:'',
      },

      rules: {
        versionName:[
          { required: true,message: '请输入版型名' ,trigger: 'blur' }

        ],
        price:[
          { required: true, message: '请输入价格', },
        ],
        wage:[
          { required: true, message: '请输入工人工资', },
        ],
      }
    }
  },




  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },



    submitForm(formName) {
      const _this = this
      this.$refs[formName].validate((valid) => {
        if (valid){
          console.log(this.ruleForm)
          axios.post(this.api.URL+'version/VUEaddVersion',this.ruleForm).then((resp)=>{
            if(resp.data == "ok"){
              this.$message({
                type: 'success',
                message: '添加成功!'
              });
              // console.log(resp.data)
              this.$router.push({
                path:"/companyManage",
                //这里传的是一个对象

              })
            }else {
              this.$alert('添加失败', '失败', {
                confirmButtonText: '确定',
              });
            }
          })


        }
      })
    }
  }
}
</script>
