<template>
  <el-container>
    <el-form>
      <div style="width: 800px ; text-align: center;font-size: 22px">
        新增计件信息
      </div>


      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" style="margin-left: 100px" >
        <el-form-item label="服装厂" prop="companyId">
          <el-select @change="search('ruleForm')"  @focus="clear('ruleForm')"  v-model="ruleForm.companyId" filterable placeholder="请选择"  style="width: 230px">
            <el-option
                v-for="item in dataGet.companyIdOption"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服装型号" prop="versionId">
          <el-select v-model="ruleForm.versionId" filterable placeholder="请选择"style="width: 230px">
            <el-option
                v-for="item in dataGet.versionIdOption"
                :key="item.id"
                :label="item.versionName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="件数" prop="count">
          <el-input v-model.number="ruleForm.count" style="width: 230px" ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">生成订单</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-form>
  </el-container>








</template>

<script>
import axios from "axios";

export default {
  data() {
    return {

      isSubmitting: false, // 添加标志位

      goodsVO:[],
      ruleForm:{
        companyId: '',
        versionId: '',
        count:'',
      },
      dataGet:{
        companyIdOption: [{}],
        versionIdOption: [{}],

      },
      rules: {
        companyId:[
          { required: true, message: '请选择服装厂', },
        ],
        versionId:[
          { required: true, message: '请选择服装型号', },
        ],
        count: [
          { required: true, message: '请输入计件数量', },
          { type: 'number' }
        ],
      }
    }
  },



  created(){
    const _this = this

    _this.goodsVO = this.$route.query.goodsVO
    axios.get(this.api.URL+'company/showAllCompany/').then(function (resp){
      // _this.product = resp.data.records
      // console.log(resp.data)
      _this.dataGet.companyIdOption = resp.data
    })

    if(this.$route.query.goodsVO == null){
      return ;
    }
    _this.ruleForm.companyId = _this.goodsVO.companyId
    axios.get(this.api.URL+'version/VUEshowVersionByCompanyId/'+this.ruleForm.companyId).then(function (resp){
      // _this.product = resp.data.records
      _this.dataGet.versionIdOption = resp.data
    })
    _this.ruleForm.versionId = _this.goodsVO.versionId
  },





  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    clear(){
      const _this = this
      _this.dataGet.versionIdOption = {}
      _this.ruleForm.versionId = ''
    },

    search(){
      const _this = this

      // axios.get('http://localhost:8181/version/VUEgetAll/').then(function (resp){
      axios.get(this.api.URL+'version/VUEshowVersionByCompanyId/'+this.ruleForm.companyId).then(function (resp){
        // _this.product = resp.data.records
        _this.dataGet.versionIdOption = resp.data
      })

    },
    // submitForm(formName) {
    //   if (this.isSubmitting) {
    //     return; // 如果正在提交，不允许再次点击
    //   }
    //
    //   this.isSubmitting = true; // 设置标志位
    //
    //   const _this = this
    //   this.$refs[formName].validate((valid) => {
    //     if (valid){
    //       axios.post(this.api.URL+'goods/addGoods',this.ruleForm).then((resp)=>{
    //         if(resp!=null){
    //           this.$message({
    //             type: 'success',
    //             message: '添加成功!'
    //           });
    //           this.$router.push({
    //             path:"/goodsPrint",
    //             //这里传的是一个对象
    //             query:{
    //               goodsVO:resp.data
    //             },
    //           });
    //           // console.log(resp.data)
    //
    //         }
    //       })
    //     }
    //   })
    // }

    submitForm(formName) {
      if (this.isSubmitting) {
        return; // 如果正在提交，不允许再次点击
      }





      const _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {

          setTimeout(function () {
            this.isSubmitting = true; // 设置标志位
          }, 10000);
          axios.post(this.api.URL + 'goods/addGoods', this.ruleForm).then((resp) => {
            if (resp != null) {
              this.$message({
                type: 'success',
                message: '添加成功!'
              });

              this.$router.push({
                path: "/goodsPrint",
                query: {
                  goodsVO: resp.data
                },
              });
            }
          }).finally(() => {

            setTimeout(function () {
              this.isSubmitting = false; // 请求完成后重置标志位
            }, 1000);
          });
        } else {
          setTimeout(function () {
            this.isSubmitting = false; // 请求完成后重置标志位
          }, 20000);
        }
      });
    }
  }
}
</script>
