<template>
  <div class="home">
    <header>
      <div class="title">
<!--        潮流时装-->
      </div>
      <div class="navbar">
        <a href="#"><div class="nav-item">首页</div></a>
<!--        <a href="contact"><div class="nav-item">联系我们</div></a>-->
        <a href="login"><div class="nav-item">登录</div></a>
      </div>
    </header>
    <div class="banner">
      <div class="banner-image">
        <div style="padding-top:150px;text-align: center;color: rgb(255, 255, 255); font-size: 120px; background-color: transparent;"><font  face="Times New Roman, 微软雅黑">FASHION</font></div>
        <div style="color: rgb(255, 255, 255); font-size: 22px; background-color: transparent;text-align: center;">潮流时装触手可及</div>
      </div>
    </div>
    <div class="rating">
      <h2>羽绒评判标准</h2>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">GB/T10288-2016<br>羽绒羽毛检验方法</li>
        <li style="display: inline-block;width: 13.33%;">FP蓬松度<br>立方英寸/30克</li>
        <li style="display: inline-block;width: 13.33%;">DP蓬松度<br>立方厘米/1克</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">12 cm</li>
        <li style="display: inline-block;width: 13.33%;">477蓬</li>
        <li style="display: inline-block;width: 13.33%;">261蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">13 cm</li>
        <li style="display: inline-block;width: 13.33%;">517蓬</li>
        <li style="display: inline-block;width: 13.33%;">282蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">14 cm</li>
        <li style="display: inline-block;width: 13.33%;">557蓬</li>
        <li style="display: inline-block;width: 13.33%;">304蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">15 cm</li>
        <li style="display: inline-block;width: 13.33%;">596蓬</li>
        <li style="display: inline-block;width: 13.33%;">326蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">16 cm</li>
        <li style="display: inline-block;width: 13.33%;">636蓬</li>
        <li style="display: inline-block;width: 13.33%;">347蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">17 cm</li>
        <li style="display: inline-block;width: 13.33%;">676蓬</li>
        <li style="display: inline-block;width: 13.33%;">369蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">18 cm</li>
        <li style="display: inline-block;width: 13.33%;">716蓬</li>
        <li style="display: inline-block;width: 13.33%;">391蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">19 cm</li>
        <li style="display: inline-block;width: 13.33%;">755蓬</li>
        <li style="display: inline-block;width: 13.33%;">413蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">20 cm</li>
        <li style="display: inline-block;width: 13.33%;">795蓬</li>
        <li style="display: inline-block;width: 13.33%;">434蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">21 cm</li>
        <li style="display: inline-block;width: 13.33%;">835蓬</li>
        <li style="display: inline-block;width: 13.33%;">456蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">22 cm</li>
        <li style="display: inline-block;width: 13.33%;">875蓬</li>
        <li style="display: inline-block;width: 13.33%;">478蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">23 cm</li>
        <li style="display: inline-block;width: 13.33%;">914蓬</li>
        <li style="display: inline-block;width: 13.33%;">499蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">24 cm</li>
        <li style="display: inline-block;width: 13.33%;">954蓬</li>
        <li style="display: inline-block;width: 13.33%;">521蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">25 cm</li>
        <li style="display: inline-block;width: 13.33%;">994蓬</li>
        <li style="display: inline-block;width: 13.33%;">543蓬</li>
      </ul>
      <ul style="list-style-type: none;">
        <li style="display: inline-block;width: 13.33%;">26 cm</li>
        <li style="display: inline-block;width: 13.33%;">1034蓬</li>
        <li style="display: inline-block;width: 13.33%;">565蓬</li>
      </ul>

      <!-- 其他评判标准列表 -->
    </div>
    <div class="fashion-history">
      <h2>时装发展的历程</h2>
      <ul>
<!--        <li>1900s - 时装起源</li>-->
<!--        <li>1920s - 爵士时代</li>-->
<!--        <li>1950s - 复古风潮</li>-->
<!--        <li>1980s - 波普时尚</li>-->
        <!-- 其他历史事件列表 -->
      </ul>
      <img src="../assets/history.webp"></img>
    </div>
    <footer>
      <div class="footer-content">
        <div>
          <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2022028997号-1</a>

          &nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://beian.mps.gov.cn/#/query/webSearch"><img src="../assets/logo01.png"width="15px">鲁公网安备37090202001435号</a>
        </div>
<!--        <p>地址: 郑州市新郑市</p>-->
<!--        <p>电话: 15737100369</p>-->
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'main',
}
</script>

<style>
.home {
  background-color: #f1f1f1;
  color: #000;
  font-family: "Arial", sans-serif;
}

header {
  background-color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 28px;
}

.navbar {
  display: flex;
  align-items: center;
}

.nav-item {
  margin-right: 10px;
  cursor: pointer;
  color: #000;
}

.banner {
  background-color: #fff;
  height: 600px;
  position: relative;
}

.banner-image {
  width: 100%;
  height: 100%;
  background-image: url("../assets/image1.jpg");
  background-position: center;
  background-size: cover;
}

.history-image {
  width: 100%;
  height: 100%;
  background-image: url("../assets/history.webp");
  background-position: center;
  background-size: cover;
}

.rating {
  background-color: #f9f9f9;
  padding: 40px;
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

.fashion-history {
  background-color: #ececec;
  padding: 40px;
  text-align: center;
  position: relative;
}

footer {
  background-color: #fff;
  color: #999;
  text-align: center;
  padding: 20px;
  font-size: 14px;
}
</style>