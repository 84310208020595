<template>
  <div style="width: 100%;">
    <div style="text-align: center;margin-top: 50px">
      <el-button type="success" ref="print" v-show="false" v-print="'#printMe'"></el-button>
      <el-button type="success" @click="print" v-if="isDataLoaded">打印</el-button>

<!--      <el-button type="success" @click="toadd" v-if="isDataLoaded">再次计件</el-button>-->
    </div>



    <div id="printMe" style="text-align: center">


      <div style="width: 350px;text-align: center ;display:inline-block;padding: 60px ;background: #B3C0D1">
        <div style="font-weight: bolder ;text-align: left;">
          <el-image :src="require('../assets/wechat.jpg')" style="background: #42b983;width: 80px;height: 80px;margin-left: 133px"></el-image>
          <div>

            <span>单号：</span><span>{{goodsVO.id}}</span>
          </div>


        </div>
        <table  border="0px " width="280px" style="margin: auto;">
        <caption style="text-align: center;font-size: 25px">
          <span style="font-weight: bold">二楼充绒厂</span>计件单据
        </caption>

        <tr>
          <th width="109">服装厂：</th>
          <td>{{goodsVO.companyName}}</td>
        </tr>
        <tr>
          <th>服装款式：</th>
          <td>{{goodsVO.versionName}}</td>
        </tr>
          <tr>
            <th>数量：</th>
            <td>{{goodsVO.count}}</td>
          </tr>
      </table>
<!--        <table border="1" width="280px"style="margin: auto;">-->
<!--        <tr>-->
<!--          <th width="109">名称</th>-->
<!--          <th>克重</th>-->
<!--        </tr>-->
<!--        <tr v-for="item in attribute">-->
<!--          <td>{{item.name}}</td>-->
<!--          <td>{{item.number}}</td>-->
<!--        </tr>-->
<!--      </table>-->

        <el-image style="width: 150px;height: 150px;margin-top: 20px" v-bind:src="image"></el-image>
        <div>

          <p style="font-weight: bold">如有发现信息错误，请立即联系充绒厂</p>
          <p><span style="font-weight: bold">电话：</span>15737100396</p>
          <p><span style="font-weight: bold">地址：</span>于寨村 红楼小广场路东</p>
        </div>
      </div>



      <div style="width: 350px; text-align: center;display:inline-block;padding: 60px;background: #B3C0D1"  >
        <div style="font-weight: bolder ;text-align: left;">
<!--          <image src="../../assets/wechat.jpg"></image>-->
          <el-image :src="require('../assets/wechat.jpg')" style="background: #42b983;width: 80px;height: 80px;margin-left: 133px"></el-image>
          <div>

            <span>单号：</span><span>{{goodsVO.id}}</span>
          </div>


        </div>
        <table  border="0px " width="280px" style="margin: auto;">
        <caption style="text-align: center;font-size: 25px">
          <span style="font-weight: bold">二楼充绒厂</span>计件单据</caption>
        <tr>
          <th width="109">服装厂：</th>
          <td>{{goodsVO.companyName}}</td>
        </tr>
        <tr>
          <th>服装款式：</th>
          <td>{{goodsVO.versionName}}</td>
        </tr>
          <tr>
            <th>数量：</th>
            <td>{{goodsVO.count}}</td>
          </tr>
      </table>
<!--        <table border="1" width="280px"style="margin: auto;">-->
<!--        <tr>-->
<!--          <th width="109">名称</th>-->
<!--          <th>克重</th>-->
<!--        </tr>-->
<!--        <tr v-for="item in attribute">-->
<!--          <td>{{item.name}}</td>-->
<!--          <td>{{item.number}}</td>-->
<!--        </tr>-->
<!--      </table>-->
        <el-image style="width: 150px;height: 150px;margin-top: 20px" v-bind:src="image"></el-image>
        <div>

          <p style="font-weight: bold">如有发现信息错误，请立即联系充绒厂</p>
          <p><span style="font-weight: bold">电话：</span>15737100396</p>
          <p><span style="font-weight: bold">地址：</span>于寨村 红楼小广场路东</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import print from 'vue-print-nb'



export default {
  directives:{
    print,
  },



  name: "PrintGoods",
  data(){
    return{
      isDataLoaded: false, // 初始时数据未加载完成
      goodsVO:{},
      // attribute:[],
      image:'',

    }
  },


  created() {
    const _this = this
    _this.goodsVO = this.$route.query.goodsVO
    var id = this.$route.query.goodsVO.id

    const storedData = localStorage.getItem('myDataGoods');
    const storedDataImage = localStorage.getItem('myDataImage');



    if(id != undefined){
      axios.get(this.api.URL+'goods/VUEfindGoodsByIdInteger/'+id,).then(function (resp){
        _this.goodsVO = resp.data;
        // 数据加载完成后保存到localStorage
        localStorage.setItem('myDataGoods', JSON.stringify(_this.goodsVO));
      })

      axios.get(this.api.URL+"goods/VUEgetQRCode/"+id).then(function (resp){
        console.log(resp.data)
        _this.image = resp.data
        // 数据加载完成后保存到localStorage
        localStorage.setItem('myDataImage', JSON.stringify(_this.image));
        setTimeout(function () {
          _this.isDataLoaded = true;
        }, 1000);

      })
    }else {
      if (storedData) {
        _this.goodsVO = JSON.parse(storedData);
        _this.image = JSON.parse(storedDataImage)
        setTimeout(function () {
          _this.isDataLoaded = true;
        }, 1000);
      }
    }





    // console.log(this.$route.query.goodsVO.versionId)
    // axios.get("http://localhost:8181/attribute/VUEShowAllByVersionId/"+_this.goodsVO.versionId).then(function (resp){
    //   // console.log(resp.data)
    //   _this.attribute = resp.data
    // })


  },



  methods:{
    print(){
      this.$refs.print.$el.click();
    },



    toadd(){
      const _this = this
      this.$router.push({

        path:"/goodsNew",
        query:{
          goodsVO:_this.goodsVO
        }
      })
    }
  }
}
</script>



<style scoped>
table{
  border-collapse:collapse;
  border-spacing:0;
  border-left:1px solid #888;
  border-top:1px solid #888;
}

th,td{
  border-right:1px solid #888;
  border-bottom:1px solid #888;
  padding:5px 15px;
}

th{
  font-weight:bold;
}
</style>