<template>
  <el-container style="height: 100%; ">
    <el-header style="text-align: center; font-size: 27px ;background: white;margin: 8px">
      <span>充绒厂管理系统</span>
    </el-header>

    <el-container style=" border: 1px solid #eee">

      <el-aside width="200px" style="background-color: rgb(238, 241, 246);">

        <el-menu router :default-openeds="['0', '1','2']">
          <el-submenu v-for="(item,index1) in $router.options.routes" :index="index1+''"v-if="item.show"  >
            <template slot="title"  ><i class="el-icon-message"  ></i>{{item.name}}</template>
            <el-menu-item v-for="(item2,index2) in item.children" :index="item2.path" :class="$route.path==item2.path?'is-active':''" >
              <template slot="title">{{item2.name}}</template>
            </el-menu-item>
          </el-submenu>
        </el-menu>

        <!--        <el-menu :default-openeds="['1','1-4']">-->
        <!--          <el-submenu index="1"><template slot="title"><i class="el-icon-message"></i>导航一</template>-->
        <!--            <el-menu-item index="1-1"><router-link to="/pageOne">页面一</router-link></el-menu-item>-->
        <!--            <el-menu-item index="1-2"><router-link to="/pageTwo">页面二</router-link></el-menu-item>-->
        <!--            <el-menu-item index="1-3"><router-link to="/pageThree">页面三</router-link></el-menu-item>-->
        <!--            <el-submenu index="1-4">-->
        <!--              <template slot="title"><i class="el-icon-message"></i>导航</template>-->
        <!--              <el-menu-item index="1-4-1"><router-link to="/pageFour">页面四</router-link></el-menu-item>-->
        <!--            </el-submenu>-->
        <!--          </el-submenu>-->
        <!--        </el-menu>-->
      </el-aside>

      <el-container style="height: 100%;">
        <el-header style="text-align: right; font-size: 12px">
          <span>{{ name }}</span>
        </el-header>

        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>

    </el-container>

    <div style="text-align: center; padding: 10px">
      Copyright © 2023 张发财    <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2022028997号-1</a>
    </div>
  </el-container>

</template>

<script>

export default {
  name: "index",
  data(){
    return{
      name:sessionStorage.getItem("name")
    }
  }
}
</script>

<style scoped>

</style>